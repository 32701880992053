import Axios from "../axios";
import BaseUrl from "../base";

// 政策
export function getPolicyList(data) {
  return Axios({
    method: "get",
    url: BaseUrl.cs + "/policy/list",
    params: data,
  });
}
// 政策-详情
export function getPolicyDetails(data) {
  return Axios({
    method: "get",
    url: BaseUrl.cs + "/policy/findById",
    params: data,
  });
}
//  政策申报记录
export function declarationList(data) {
  return Axios({
    method: "get",
    url: BaseUrl.cs + "/declaration/list",
    params: data,
  });
}
// 申报政策
export function declarationAdd(data) {
  return Axios({
    // headers: {
    //   "Content-Type": "application/json",
    // },
    method: "post",
    url: BaseUrl.cs + "/declaration/add",
    params: data,
  });
}
